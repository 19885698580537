import React, { useEffect, useState } from 'react';
import { useEditor } from '@contexts/EditorContext';
import Select from 'react-select';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';


function SocialMediaItemStyle({ component, socialMedia }): React.Node {

    const smOptions = [
        { value: "facebook", label: <div>facebook</div> },
        { value: "instagram", label: <div>instagram</div> },
        { value: "twitter", label: <div>twitter</div> },
        { value: "tiktok", label: <div>tiktok</div> },
        { value: "linkedin", label: <div>linkedin</div> },
        { value: "youtube", label: <div>youtube</div> },
        { value: "whatsapp", label: <div>whatsapp</div> },
        { value: "pinterest", label: <div>pinterest</div> },
    ];

    const { page, selectedElement, updatePage } = useEditor();
    const { t } = useTranslation('editor');

    const [link, setLink] = useState(null);
    const [icon, setIcon] = useState(null);

    const [smType, setSmType] = useState(null);
    const [smStyle, setSmStyle] = useState(null);

    const [styleOptions, setStyleOptions] = useState(null);

    useEffect(() => {
        init();
    }, [selectedElement]);


    useEffect(() => {
        init();
    }, []);

    const init = () => {
        setLink(socialMedia.link);
        setSmType(_.filter(smOptions, { value: socialMedia.type })[0]);

        const _styleOptions = getStyleOptions(socialMedia.type)
        setStyleOptions(_styleOptions);
        setSmStyle(_.filter(_styleOptions, { value: socialMedia.imgSrc }));

    };

    const onChangeLink = (ev) => {
        setLink(ev.target.value);
        socialMedia.link = ev.target.value;
    };

    const getStyleOptions = (sm) => {
        let _styleOptions = []
        for (let i = 1; i <= 9; i++) {
            _styleOptions.push(
                {
                    value: `https://s3-emailing-prod.s3.eu-west-3.amazonaws.com/social-media/style-${i}/${sm}.png`,
                    label: <div><img width={25} src={`https://s3-emailing-prod.s3.eu-west-3.amazonaws.com/social-media/style-${i}/${sm}.png`} /></div>
                },
            )
        }

        _styleOptions.push(
            {
                value: `https://s3-emailing-prod.s3.eu-west-3.amazonaws.com/social-media/style-white/${sm}.png`,
                label: <div><img width={25} src={`https://s3-emailing-prod.s3.eu-west-3.amazonaws.com/social-media/style-white/${sm}.png`} /></div>
            },
        )
        return _styleOptions;
    }

    const onChangeType = (option) => {
        setSmType(option);
        socialMedia.type = option.value;
        setStyleOptions(getStyleOptions(option.value));
    };

    const onChangeStyle = (option) => {
        setSmStyle(option);
        socialMedia.imgSrc = option.value;
        updatePage(page, true);
    };
    const onChangeImgSrc = (e) => {
        socialMedia.imgSrc = e.target.value;
        updatePage(page, true);
    };


    const selectStyles = {
        container: (base, state) => ({
            ...base,
            // zIndex: "99"
        }),
        // option: (base, state) => ({
        //     ...base,
        //     zIndex: "99"
        // }),
        menuPortal: (base, state) => ({
            ...base,
            zIndex: "99"
        })
    };


    return (
        <React.Fragment>
            <label class=" form-label" for="input-model">{t("right-panel.social-media.link-label")}</label>
            <input name="link" type="text" class="form-control" value={link} onChange={onChangeLink} />
            <div>
                <label class="form-label" for="input-model">{t("right-panel.social-media.social-media-label")}</label>
                <div className="row mb-3">
                    <div className="col-6">
                        <Select
                            value={smType}
                            styles={selectStyles}
                            onChange={onChangeType}
                            // menuPlacement="auto"
                            menuPosition="fixed"
                            options={smOptions}
                        />
                    </div>
                    <div className="col-6">
                        <Select
                            value={smStyle}
                            styles={selectStyles}
                            onChange={onChangeStyle}
                            // menuPlacement="auto"
                            menuPosition="fixed"
                            options={styleOptions}
                        />
                    </div>
                </div>
                <input name="imgSrc" type="text" class="form-control" value={socialMedia.imgSrc} onChange={onChangeImgSrc} />
            </div>
        </React.Fragment >
    );
}

export default SocialMediaItemStyle;
